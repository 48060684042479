import { init } from './util'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

function initCkEditor(e, inserted = null) {
  $(inserted ? inserted : document).find('.ck-textarea').each(function () {
    ClassicEditor.create(this, {
      // plugins: [MediaEmbed],
      // removePlugins: ["ImageUpload"],
      toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "blockQuote", "insertTable", "undo", "redo", "image"]
    })
  })
}

$(document).on('turbolinks:load', function () {
  init(initCkEditor)
});
