$(document).on('turbolinks:load', function () {
  jQuery(".institutions_select").select2({
    theme: 'bootstrap4',
    width: '100%',
    minimumResultsForSearch: 1,
    containerCssClass: ':all:',
    ajax: {
      url: '/admin/institutions.json',
      delay: 150,
      data: function (params) {
        var query = {
          q: {
            institution_name_cont: params.term
          }
        };
        return query;
      }
    }
  });

  jQuery(".users_select").select2({
    theme: 'bootstrap4',
    width: '100%',
    minimumResultsForSearch: 1,
    containerCssClass: ':all:',
    ajax: {
      url: '/admin/contacts.json',
      delay: 150,
      data: function (params) {
        var query = {
          q: {
            full_name_or_email_cont: params.term,
            email_present: jQuery(this).data("ransack-email-present")
          }
        };
        return query;
      }
    }
  });

  var placeholder = "<i class='fas fa-search'></i> Institution/Contacts";
  jQuery(".institutions_users_select").select2({
    theme: 'bootstrap4',
    width: '100%',
    minimumResultsForSearch: 1,
    containerCssClass: ':all:',
    placeholder: placeholder,
    ajax: {
      url: '/admin/search.json',
      delay: 150
    },
    escapeMarkup: function (m) {
      return m;
    }
  });

  jQuery('.institutions_users_select').on('select2:select', function (e) {
    var data = e.params.data;
    // location.href = ;
    window.open(
      data.id,
      '_blank' // <- This is what makes it open in a new window.
    );
  });

});