import { init } from './init'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/themes/base/theme.css'
import 'jquery-ui/themes/base/datepicker.css'

function datepicker(e, inserted = null) {
  $(inserted ? inserted : document).find('.datepicker').each(function () {
    let elem = $(this),
      config = {}

    if (elem.data('minDate')) config.minDate = elem.data('minDate')
    elem.datepicker(config)

    if (elem.data('from')) {
      elem.on('change', function () {
        $(elem.data('from')).datepicker('option', 'maxDate', getDate(this))
      })
    }

    if (elem.data('to')) {
      elem.on('change', function () {
        $(elem.data('to')).datepicker('option', 'minDate', getDate(this))
      })
    }
  })
}

function getDate(element) {
  let date;
  try {
    date = $.datepicker.parseDate('mm/dd/yy', element.value);
  } catch (error) {
    date = null;
  }

  return date;
}

init(datepicker)

export default function initDatepicker() { init(datepicker) }