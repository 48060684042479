// import 'babel-polyfill'
import 'bootstrap'
import 'autosize'
import LocalTime from "local-time"
import initSelect2 from './init-select2'
import initDatepicker from './datepicker'
import * as ActiveStorage from 'activestorage';
import "./tooltip";

// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/solid'
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'
var Turbolinks = require("turbolinks")
var autosize = require("autosize")
Turbolinks.start()
ActiveStorage.start();
import './select2-ajax.js'
import './ckeditor'
import axios from 'axios'


axios.defaults.headers.common['Accept'] = 'application/json';

$(document).on('turbolinks:load', function () {
  LocalTime.start();
  initSelect2();
  autosize($('textarea.autosize'));
  initDatepicker();

  jQuery('.collapse').on('shown.bs.collapse', function(e) {
    var $card = $(this).closest('.card');
    $('html,body').animate({
      scrollTop: $card.offset().top - 120
    }, 500);
  });



  jQuery(".action-buttons a").click(function () {
    var textToPrepend = jQuery(this).data("text");
    jQuery("#institution_notes").val(textToPrepend + "\n\n" + jQuery("#institution_notes").val());
  });
  // jQuery("#institution_institution_name").keydown(function(){
  //   alert("You are editing the institution name");
  // })

  const inputEl = document.querySelector('#institution_institution_name');
  let temp;

  //Update the temp value each time we focus on the input
  inputEl.addEventListener('focus', e => {
    temp = e.target.value;
  });

  //Confirm that the user wants to keep their changes
  inputEl.addEventListener('change', e => {
    var confirmed = confirm('Did you really want to edit the institution name?');
    if (!confirmed) {
      e.target.value = temp;
    }
  })
});

document.addEventListener("turbolinks:before-cache", () => {
  jQuery(document).find("select").not(".native").each(function () {
    jQuery(this).select2('destroy');
  });
});