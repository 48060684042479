import { init } from './init'
import 'select2/dist/js/select2.full.js'

export function select2(element, options = {}) {
  let tags = element.data('select-tags') || false
  element.select2({
    theme: 'bootstrap4',
    tags,
    width: '100%',
    minimumResultsForSearch: 10,
    containerCssClass: ':all:',
    ...options
  })
}

function buildSelect2(e, inserted = null) {
  $(inserted ? inserted : document).find('select').not('.native').each(function() {
    select2($(this))
  })
}

export default function initSelect2() { init(buildSelect2) }
