// utility function to initialize jQuery libraries
// on document ready
function init(fn) {
  $(fn)
  jQuery(fn)
}

export {
  init
}
